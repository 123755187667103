import L from 'leaflet'
import { useQuery } from 'react-query'
import { useRequest } from './useRequest'
import { MapLayerConfig } from '../types/map'
import { AxiosInstance, AxiosResponse } from 'axios'

const LAYER_CACHE_SECONDS = 60 * 5 // 5 minutes
const MIN_LAYER_ZOOM = 10
const MIN_TOOLTIP_ZOOM = 15

const VISIBLE_LAYER_STYLE = { opacity: 1, fillOpacity: 0.7 }
const VISIBLE_TOOLTIP_OPACITY = 1

const createLayerVisibilityZoomHandler = (map: L.Map, layer: L.FeatureGroup) => {
  const shouldLayerBeVisible = () => map.getZoom() >= MIN_LAYER_ZOOM
  const shouldTooltipBeVisible = () => map.getZoom() >= MIN_TOOLTIP_ZOOM

  // Initialise our state with the current zoom level.
  const state = {
    layerVisible: shouldLayerBeVisible(),
    tooltipsVisible: shouldTooltipBeVisible(),
  }

  const showLayer = () => {
    state.layerVisible = true
    layer.setStyle(VISIBLE_LAYER_STYLE)
  }

  const hideLayer = () => {
    state.layerVisible = false
    layer.setStyle({ opacity: 0, fillOpacity: 0 })
  }

  const showTooltips = () => {
    state.tooltipsVisible = true
    layer.eachLayer((layer) => {
      layer.getTooltip()?.setOpacity(VISIBLE_TOOLTIP_OPACITY)
    })
  }

  const hideTooltips = () => {
    state.tooltipsVisible = false
    layer.eachLayer((layer) => {
      layer.getTooltip()?.setOpacity(0)
    })
  }

  // Set the initial visibility.
  if (shouldLayerBeVisible()) {
    showLayer()
  } else {
    hideLayer()
  }
  if (shouldTooltipBeVisible()) {
    showTooltips()
  } else {
    hideTooltips()
  }

  return () => {
    // Check the visibility flags so we don't run on every zoom level change.
    if (!state.layerVisible && shouldLayerBeVisible()) {
      showLayer()
    } else if (state.layerVisible && !shouldLayerBeVisible()) {
      hideLayer()
    }
    if (!state.tooltipsVisible && shouldTooltipBeVisible()) {
      showTooltips()
    } else if (state.tooltipsVisible && !shouldTooltipBeVisible()) {
      hideTooltips()
    }
  }
}

function delayedFetch(client: AxiosInstance, url: string, delayDuration = 3000): Promise<AxiosResponse> {
  // This function can be used in situations where call stacks on the server need to be controlled (first in first out).
  // For greedy queries, this function can be used to delay the triggering of a request by a set amount
  // of time to allow other requests to be processed first.
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      client
        .get(url)
        .then((res) => resolve(res))
        .catch(reject)
    }, delayDuration)
  })
}

export function usePropertyBoundariesLayer({
  excludePlanIds,
  style,
}: { excludePlanIds?: string[]; style?: Omit<L.PathOptions, 'opacity' | 'fillOpacity'> } = {}) {
  const { client } = useRequest()
  const { data, isLoading } = useQuery(
    ['property-boundaries-layer'],
    () => delayedFetch(client, `api/property-boundaries-layer`).then((res) => res.data),
    { cacheTime: LAYER_CACHE_SECONDS },
  )

  const config: MapLayerConfig = { name: 'SRP property boundaries', enabledByDefault: true }

  const layer = new L.GeoJSON(data, {
    style: { stroke: true, color: '#404040', fillColor: '#808080', weight: 2, ...style, ...VISIBLE_LAYER_STYLE },
    onEachFeature: (geojsonFeature, featureLayer) => {
      featureLayer.bindTooltip(`SRP: ${geojsonFeature.properties?.planIdentifier}`, {
        className: 'c-polygon-label',
        permanent: true,
        direction: 'center',
        opacity: VISIBLE_TOOLTIP_OPACITY,
        interactive: false,
      })
    },
    filter: (feature) => {
      if (!excludePlanIds || excludePlanIds.length === 0) return true
      return !excludePlanIds.includes(`${feature.properties?.planId}`)
    },
  })

  layer.on('add', (e) => {
    // Ensure this layer is behind the drawable layer.
    layer.bringToBack()

    const map: L.Map | undefined = (e.target as { _map: L.Map | undefined })?._map
    if (!map) return

    const onZoom = createLayerVisibilityZoomHandler(map, layer)

    // Run the handler once to set the initial visibility.
    // onZoom()

    /* Add the zoom handler and remove it if the layer is removed so we don't
     * end up with multiple handlers running. */
    map.on('zoom', onZoom)
    layer.on('remove', () => {
      map.off('zoom', onZoom)
    })
  })

  return { layer: { config, layer }, isLoading }
}
